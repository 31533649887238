import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


const api = createApi({
    reducerPath:'api',
    baseQuery: fetchBaseQuery({ baseUrl: 'https://servers.thakurgaonexpress.com/bondhuboxhouse/' }),
    tagTypes:[],
    endpoints: () => ({ }),
})

export default api;