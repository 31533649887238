import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../../Shared/Loader';


const UpdateModal = ({ updateCollection, setUpdateCollection,fetchData }) => {
    const userAc = useSelector((state) => state.user.user);
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isCustomerSearching, setIsCustomerSearching] = useState(false);
    const [customerText, setCustomerText] = useState([]);
    const [customerSearch, setCustomerSearch] = useState([]);
    const [currentCustomer, setCurrentCustomer] = useState([]);
    const [isSelectCustomer, setIsSelectCustomer] = useState(false);



    const { _id, date, code, name, invoice, amount, purpose, status } = updateCollection;

    useEffect(() => {
        fetch('https://servers.thakurgaonexpress.com/bondhuboxhouse/getmaincustomers')
            .then(res => res.json())
            .then(data => {
                setCustomers(data);
                setLoading(false)
            })
    }, [])

    const onSubmit = (data) => {
        const name=currentCustomer?.name;
        const code=currentCustomer?.code;
        const collection = parseInt(data.amount);
        const user = userAc?.email.split('@')[0];
        const approvedBy = false;
        const newData = { ...data,name,code, collection,user,approvedBy }
        fetch(`https://servers.thakurgaonexpress.com/bondhuboxhouse/getcollection/${_id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(newData)
        })
            .then(res => res.json())
            .then(data => {
                setUpdateCollection(null);
                toast('Collection Upadated Successful');
                fetchData();
            })
    }

    const noEdit = () => {
        window.location.reload(false);
    }

    if (loading) {
        return <Loader />
    }

    const handleSearchCustomer = (event) => {
        setCustomerText(event.target.value)
        const match = customers.filter(customer => customer.name.toLowerCase().includes(customerText)
            || customer.name.includes(customerText) || customer.name.toUpperCase().includes(customerText));
        setCustomerSearch(match);
        setIsCustomerSearching(true);
    }

    const handleSelectCustomer = (customer,event) => {
        event.preventDefault();
        event.stopPropagation();
        const opening = parseFloat(customer?.opening)
        const url = `https://servers.thakurgaonexpress.com/bondhuboxhouse/filteredmaincustomer?code=${customer.code}`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                const due = opening + data?.defaultTotalDue - data?.defaultTotalCollect;
                setCurrentCustomer({ ...customer, due });
                setIsSelectCustomer(true)
            })
        setIsCustomerSearching(false);
        setCustomerText('');
    }

    return (
        <div>
            <input type="checkbox" id="update-modal" class="modal-toggle" />
            <div class="modal mt-12">
                <div class="modal-box relative text-xs">
                    <label for="update-modal" onClick={() => noEdit()} class="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <h3 class="text-lg font-bold">Update Collection of-{code}. {name}</h3>
                    <div class="form-control w-full max-w-xs mt-5 col-span-2 mx-auto">
                        <div class="form-control w-full">
                            <input onChange={handleSearchCustomer} value={customerText} type="text" placeholder="Search Curstomer" class="rounded-lg bg-red-300 p-3 placeholder-black  text-center  focus:border-blue-500 focus:outline-none w-full max-w-xs" />
                        </div>
                        <div style={isCustomerSearching ? {} : { visibility: 'hidden' }} className={`grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 bg-gray-200 p-2 ${isCustomerSearching ? 'h-16' : "h-8"} w-full overflow-auto`}>
                            {
                                isCustomerSearching ? customerSearch.map(customer =>
                                    <label for="update-modal" onClick={event => handleSelectCustomer(customer,event)} style={{ width: '300px', height: '50px' }} className={`bg-black bg-opacity-40 p-3 text-white text-xs shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer`}>{customer.code}. {customer?.name}</label>) :
                                    customers.map(customer =>
                                        <label for="update-modal" onClick={event => handleSelectCustomer(customer,event)} style={{ width: '300px', height: '50px' }} className={`bg-black bg-opacity-40 p-3 text-white text-xs shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer`}>{customer.code}. {customer?.name}</label>)
                            }
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} className='p-5 grid grid-cols-1 gap-4 justify-items-center'>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Select Date</span>
                            </label>
                            <input {...register("date")} type="date" defaultValue={date} placeholder="Date" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Customer Name</span>
                            </label>
                            <input  {...register("name")} type="text" value={!isSelectCustomer ? name : currentCustomer?.name || ''} placeholder="Type Invoice No Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Customer Code</span>
                            </label>
                            <input  {...register("code")} type="text"  value={!isSelectCustomer ? code : currentCustomer?.code || ''} placeholder="Type Invoice No Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Invoice No</span>
                            </label>
                            <input {...register("invoice")} type="text" defaultValue={invoice} placeholder="Type Invoice No Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Amount</span>
                            </label>
                            <input {...register("amount")} type="number" defaultValue={amount} placeholder="Type Amount Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Purpose</span>
                            </label>
                            <input {...register("purpose")} type="text" defaultValue={purpose} placeholder="Type Collection Purpose Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Status</span>
                            </label>
                            <select style={{ padding: '18px' }} {...register("status")} defaultValue={status} class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                                <option >Active</option>
                                <option>Inactive</option>
                            </select>
                        </div>
                        <input type='submit' value='Update Collection' className='btn bg-red-600 text-white' />
                    </form>
                </div>
            </div>
        </div >
    );
};

export default UpdateModal;