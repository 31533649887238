import logo from '../../assets/images/logo.jpg'

const BusinessInfo = {
    name: "Bondhu Box House",
    fullName: "M/S Bondhu Box House",
    dealer: "(Maker and Supplier of Sweet, Biriyani, Cake and Shoe Box)",
    address: "Kawraid, Sreepur, Gazipur",
    mobile: "Mobile: 01924-909352,01747-890960",
    img: logo,
}

export default BusinessInfo;
