import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filterServices, getAllCompletedService } from '../../features/serviceReportSlice/serviceReportSlice';
import DaywiseProfitGraph from '../Shared/DashboardCharts/DaywiseProfitGraph';
import DaywiseSalesGraph from '../Shared/DashboardCharts/DaywiseSalesGraph';
import MonthwiseSalesGraph from '../Shared/DashboardCharts/MonthwiseSalesGraph';
import ThisMonthSales from '../Shared/DashboardCharts/ThisMonthSales';
import TodaySales from '../Shared/DashboardCharts/TodaySales';
import TodayProfit from '../Shared/DashboardCharts/TodayProfit';
import ThisMonthProfit from '../Shared/DashboardCharts/ThisMonthProfit';
import MonthwiseProfitGraph from '../Shared/DashboardCharts/MonthwiseProfitGraph';
import ProfitExpenseNett from '../Shared/DashboardCharts/ProfitExpenseNett';
import ProfitExpenseNettAllTime from '../Shared/DashboardCharts/ProfitExpenseNettAllTime';
import ThisMonthIncomes from '../Shared/DashboardCharts/ThisMonthIncomes';
import ThisMonthExpenses from '../Shared/DashboardCharts/ThisMonthExpenses';

const DashboardHome = () => {
    const [services, setServices] = useState([]);
    const dispatch = useDispatch();
    const allServices = useSelector((state) => state.serviceReport.completedService);
    const filtering = useSelector((state) => state.serviceReport.filterBy);
    const userAc = useSelector((state) => state.user.user);
    const user = userAc?.email.split('@')[0];



    const date = new Date();
    const today = date.toLocaleDateString();
    const thisMonth = date.toLocaleString('default', { month: 'short' });
    const thisYear = today.slice(-4);
    // console.log(services);
    // console.log(services[0].date.slice(-4));

    //'Thu Apr 13 2023 10:24:25 GMT+0600 (Bangladesh Standard Time)'
    useEffect(() => {
        fetch(`https://servers.thakurgaonexpress.com/bondhuboxhouse/getbooking`)
            .then(res => res.json())
            .then(data => {
                const filter = data.filter(book => book?.technician);
                // dispatch(getAllCompletedService(filter))
                if (filtering === "Today") {
                    const todayService = filter.filter(service => service.date === today)
                    setServices(todayService);
                }
                if (filtering === "This Month") {
                    const thisMonthService = filter.filter(service => new Date(service.date).toLocaleString('default', { month: 'short' }) === thisMonth)
                    setServices(thisMonthService);
                }
                if (filtering === "This Year") {
                    const thisYearService = filter.filter(service => service?.date?.slice(-4) === thisYear)
                    setServices(thisYearService);
                }

            })
    }, [services, setServices])





    const totalFree = services?.filter(service => service?.service?.includes("Free"));
    const totalPaid = services?.filter(service => service?.service?.includes("Paid"));
    const totalSpare = services.reduce((total, currentValue) => total + parseInt(currentValue?.parts), 0);
    const freeCharge = totalFree.reduce((total, currentValue) => total + parseInt(currentValue?.service_charge), 0);
    const paidCharge = totalPaid.reduce((total, currentValue) => total + parseInt(currentValue?.service_charge), 0);


    // For Technician Wise Report Start

    const filter = 'technician'
    const uniqueTechnician = [...new Map(services.map(service =>
        [service[filter], service])).values()];

    let array = [];
    for (let i = 0; i < uniqueTechnician.length; i++) {
        const filtered = services.filter(service => service.technician === uniqueTechnician[i].technician);
        array.push(filtered);

    }
    let freeServiceArray = [];
    let freeServiceQyt = []

    for (let i = 0; i < array?.length; i++) {
        const technicianAmount = array[i]?.filter(s => s.service.includes("Free")).map(a => a.service_charge);
        const totalFreeService = technicianAmount?.reduce((a, b) => parseInt(a) + parseInt(b), 0);
        freeServiceArray.push(totalFreeService);
        freeServiceQyt.push(technicianAmount?.length);
    }
    let paidServiceArray = [];
    let paidServiceQyt = [];

    for (let i = 0; i < array?.length; i++) {
        const technicianAmount = array[i]?.filter(s => s.service.includes("Paid")).map(a => a.service_charge);
        const totalPaidService = technicianAmount?.reduce((a, b) => parseInt(a) + parseInt(b), 0);
        paidServiceArray.push(totalPaidService);
        paidServiceQyt.push(technicianAmount?.length);
    }
    let totalServiceArray = [];
    let totalServiceQyt = [];

    for (let i = 0; i < array?.length; i++) {
        const technicianAmount = array[i]?.map(a => a.service_charge);
        const totalService = technicianAmount?.reduce((a, b) => parseInt(a) + parseInt(b), 0);
        totalServiceArray.push(totalService);
        totalServiceQyt.push(technicianAmount?.length);
    }
    let totalPartsArray = [];

    for (let i = 0; i < array?.length; i++) {
        const technicianAmount = array[i]?.map(a => a.parts);
        const totalParts = technicianAmount?.reduce((a, b) => parseInt(a) + parseInt(b), 0);
        totalPartsArray.push(totalParts);
    }

    let technicianData = [];
    for (let i = 0; i < uniqueTechnician?.length; i++) {
        const data = ({
            name: uniqueTechnician[i]?.technician, freeService: freeServiceArray[i], paidService: paidServiceArray[i],
            totalService: totalServiceArray[i], partsSell: totalPartsArray[i], fsQyt: freeServiceQyt[i], psQyt: paidServiceQyt[i],
            tsQyt: totalServiceQyt[i],
        });
        technicianData.push(data);
    }

    // End Technician Wise Report

    return (
        <div className='mb-40'>
            <h1 className='text-xl lg:hidden font-bold my-4 uppercase'>Welcome {user}</h1>
            {/* <div className='mt-12 mb-12 text-right font-bold'>
                <button onClick={() => dispatch(filterServices("Today"))} className={`btn ${filtering==="Today"?'btn-success':'btn-error'} text-white mr-2`}>Today</button>
                <button onClick={() => dispatch(filterServices("This Month"))} className={`btn ${filtering==="This Month"?'btn-success':'btn-error'} text-white mr-2`}>This Month</button>
                <button onClick={() => dispatch(filterServices("This Year"))} className={`btn ${filtering==="This Year"?'btn-success':'btn-error'} text-white mr-2`}>This Year</button>
                <button disabled onClick={() => dispatch(filterServices("Custom"))} className={`btn ${filtering==="Custom"?'btn-success':'btn-error'} text-white mr-2`}>Custom</button>
            </div> */}
            {/* <div>
                <div className='flex gap-px justify-center flex-col lg:flex-row'>
                    <div>
                        <table class="table w-1/2 mx-auto">
                            <caption>Service Quantity {filtering==="Today"?"(Today)":filtering==="This Month"?"(This Month)":filtering==="This Year"?"(This Year)":""}</caption>
                            <thead className='text-center'>
                                <tr>
                                    <th className='bg-red-600 text-white normal-case'>Total Service</th>
                                    <th className='bg-red-600 text-white normal-case'>Free Service</th>
                                    <th className='bg-red-600 text-white normal-case'>Paid Service</th>
                                </tr>
                            </thead>
                            <tbody className='text-center'>
                                <tr className='border border-1'>
                                    <td>{services?.length}</td>
                                    <td>{totalFree?.length}</td>
                                    <td>{totalPaid?.length}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div>
                        <table class="table w-1/2 mx-auto">
                            <caption>Service Amount {filtering==="Today"?"(Today)":filtering==="This Month"?"(This Month)":filtering==="This Year"?"(This Year)":""}</caption>
                            <thead className='text-center'>
                                <tr>
                                    <th className='bg-red-600 text-white normal-case'>Spare Parts Sell</th>
                                    <th className='bg-red-600 text-white normal-case'>FS Charge</th>
                                    <th className='bg-red-600 text-white normal-case'>PS Charge</th>
                                    <th className='bg-red-600 text-white normal-case'>Total</th>
                                </tr>
                            </thead>
                            <tbody className='text-center'>
                                <tr className='border border-1'>
                                    <td>{totalSpare}</td>
                                    <td>{freeCharge}</td>
                                    <td>{paidCharge}</td>
                                    <td>{totalSpare + paidCharge}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div>
                        <table class="table w-1/2 mx-auto">
                            <caption>Technician Wise Service {filtering==="Today"?"(Today)":filtering==="This Month"?"(This Month)":filtering==="This Year"?"(This Year)":""}</caption>
                            <thead className='text-center'>
                                <tr>
                                    <th className='bg-red-600 text-white normal-case'>Technician</th>
                                    <th className='bg-red-600 text-white normal-case'>Spare Parts</th>
                                    <th className='bg-red-600 text-white normal-case'>Total Charge</th>
                                    <th className='bg-red-600 text-white normal-case'>FS Charge</th>
                                    <th className='bg-red-600 text-white normal-case'>PS Charge</th>
                                    <th className='bg-red-600 text-white normal-case'>Total Amount</th>
                                </tr>
                            </thead>
                            <tbody className='text-center'>
                                {
                                    technicianData.map(technician => <tr className='border border-1'>
                                        <td>{technician?.name}</td>
                                        <td>{technician?.partsSell}</td>
                                        <td>{technician?.totalService} ({technician?.tsQyt})</td>
                                        <td>{technician?.freeService} ({technician?.fsQyt})</td>
                                        <td>{technician?.paidService} ({technician?.psQyt})</td>
                                        <td>{technician?.partsSell + technician?.freeService + technician?.paidService}</td>
                                    </tr>)
                                }
                            </tbody>
                        </table>
                    </div>
                      
                    {/* <div className="btn-group btn-group-vertical lg:btn-group-horizontal">
                        <button className="btn btn-active">Button</button>
                        <button className="btn">Button</button>
                        <button className="btn">Button</button>
                    </div> */}
            <div className='mt-4 grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3 gap-3 mx-4'>
                {userAc?.dashboardTodaySales && <TodaySales />}
                {userAc?.dashboardTodayProfit && <TodayProfit />}
                {userAc?.dashboardThisMonthSales && <ThisMonthSales />}
                {userAc?.dashboardThisMonthProfit && <ThisMonthProfit />}
                {userAc?.dashboardThisMonthIncomes && <ThisMonthIncomes />}
                {userAc?.dashboardThisMonthExpenses && <ThisMonthExpenses />}
                {/* <TodaySales/>
                        <TodaySales/> */}
            </div>

            <div className='grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 justify-center items-center mx-4'>
                {userAc?.dashboardProfitExpenseNett && <ProfitExpenseNett />}
                {userAc?.dashboardProfitExpenseNettAllTime && <ProfitExpenseNettAllTime />}
            </div>

            <div className='grid grid-cols-1 lg:grid-cols-2 gap-5 mx-4'>
                {userAc?.dashboardDaywiseSalesGraph && <DaywiseSalesGraph />}
                {userAc?.dashboardDaywiseProfitGraph && <DaywiseProfitGraph />}
                {userAc?.dashboardMonthwiseSalesGraph && <MonthwiseSalesGraph />}
                {userAc?.dashboardMonthwiseProfitGraph && <MonthwiseProfitGraph />}
            </div>


        </div>
        // </div> */}

    );
};

export default DashboardHome;