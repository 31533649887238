import React, { useEffect } from 'react';
import { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from 'recharts';
import useFetchData from '../../../hooks/useFetchData';


const DaywiseProfitGraph = () => {
  const {items:profit}=useFetchData("https://servers.thakurgaonexpress.com/bondhuboxhouse/daywiseprofit")
  const maxProfitValue = Math.max(...profit.map(item => (item.profit).toFixed(2)));

  return (
    <div className="">
      <h1 className='text-lg font-bold'>Day Wise Profit (This Month)</h1>
      <ResponsiveContainer width="100%" height={300}>
      <BarChart width={800} height={300} data={profit} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="day" />
        <YAxis domain={[0, (maxProfitValue).toFixed(0)]}/>
        <Tooltip />
        <Legend />
        <Bar dataKey="profit" fill="#00A36C" />
      </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DaywiseProfitGraph;
