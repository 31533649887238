import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../../Shared/Loader';
import useFetchData from '../../../../../hooks/useFetchData';


const ViewInvoice = ({ purchaseModal, setPurchaseModal }) => {
    const { items } = useFetchData('https://servers.thakurgaonexpress.com/bondhuboxhouse/getproductscode');
    const userAc = useSelector((state) => state.user.user);
    const products = useSelector((state) => state.purchaseOrder.products);
    const [searchResult, setSearchResult] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [searchText, setSearchText] = useState();
    const [pdCode, setPdCode] = useState('')
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    ///////////
    const [productList, setProductList] = useState([]);
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [units, setUnits] = useState([]);
    const [origins, setOrigins] = useState([]);
    const [isAddingProduct, setIsAddingProduct] = useState(false);
    const [isUpdatingProduct, setIsUpdatingProduct] = useState(false);
    const [updatingProduct, setUpdatingProduct] = useState();
    const [newProduct, setNewProduct] = useState({
        code: '',
        name: '',
        category: '',
        brand: '',
        origin: '',
        unit: '',
        purchase: '',
        sell: '',
        minimum: '',
        opening: '',
        remarks: '',
        status: 'Active',
        user: userAc?.email.split('@')[0],
        approvedBy: false,
    });

    const isExist = items?.filter(pcode => pcode?.code === pdCode);
    const duplicate = isExist?.length ? true : false;

    let barcode = productList?.length + 1;
    const getbarcode = `2023${barcode}`;
    const productCodeRef = useRef('');

    const codeRef = useRef();
    const nameRef = useRef();
    const categoryRef = useRef();
    const unitRef = useRef();
    const purchaseRef = useRef();
    const sellRef = useRef();
    const minimumRef = useRef();
    const openingRef = useRef();
    const remarksRef = useRef();

    // Function to handle adding a new product
    const handleAddProduct = () => {
        const productCode = productCodeRef.current.value;
        const opening = parseFloat(newProduct.opening);
        const minimum = parseFloat(newProduct.minimum);
        // Validate the input fields (you can add more validation as needed)
        if (!productCode || !newProduct.name || !newProduct.purchase || !newProduct.sell
            || !newProduct.category || !newProduct.unit
            || !newProduct.minimum || !newProduct.opening) {
            toast.error('Please fill in all product details.');
            return;
        };
        fetch('https://servers.thakurgaonexpress.com/bondhuboxhouse/products', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ ...newProduct, code: productCode, opening: opening, minimum: minimum }),
        })
            .then(res => res.json())
            .then(data => {
                toast.success('Product Added')
                setDataArray(prevDataArray => [...prevDataArray, {
                    ...newProduct, _id: data.insertedId,
                    opening: parseFloat(newProduct.opening), minimum: parseFloat(newProduct.minimum),
                    rate: newProduct.purchase, total: 1 * newProduct.purchase, quantity: 1, code: productCode
                }]);
                barcode = barcode + 1;
            });

        // Add the new product to the dataArray



        // Clear the input fields and reset state
        setNewProduct({
            code: '',
            name: '',
            category: '',
            brand: '',
            origin: '',
            unit: '',
            purchase: '',
            sell: '',
            minimum: '',
            opening: '',
            remarks: '',
            status: '',
            user: '',
            approvedBy: '',
        });
        setIsAddingProduct(false);
    };
    useEffect(() => {
        fetch('https://servers.thakurgaonexpress.com/bondhuboxhouse/getproducts')
            .then(res => res.json())
            .then(data => {
                setProductList(data);
                setLoading(false)
            })
    }, [productList])
    useEffect(() => {
        fetch('https://servers.thakurgaonexpress.com/bondhuboxhouse/getcategories')
            .then(res => res.json())
            .then(data => {
                setCategories(data);
                setLoading(false)
            })
    }, [productList])
    useEffect(() => {
        fetch('https://servers.thakurgaonexpress.com/bondhuboxhouse/getbrands')
            .then(res => res.json())
            .then(data => {
                setBrands(data);
                setLoading(false)
            })
    }, [])
    useEffect(() => {
        fetch('https://servers.thakurgaonexpress.com/bondhuboxhouse/getunits')
            .then(res => res.json())
            .then(data => {
                setUnits(data);
            })
    }, [])
    useEffect(() => {
        fetch('https://servers.thakurgaonexpress.com/bondhuboxhouse/getorigins')
            .then(res => res.json())
            .then(data => {
                setOrigins(data);
            })
    }, [])
    ////////////////

    const [discountAmount, setDiscountAmount] = useState('');
    const [roundingAmount, setRoundingAmount] = useState(0);

    const totalRef = useRef('');
    const discountRef = useRef();
    const grandTotalRef = useRef();
    const paymentRef = useRef();
    const dueAmountRef = useRef();
    const discountTypeRef = useRef();

    // const totalRef = useRef('');
    const invoiceRef = useRef('');
    const dateRef = useRef('')
    const beepAudioRef = useRef(null);

    const supplierCodeRef = useRef('');
    const supplierRef = useRef('');
    const addressRef = useRef('');
    const phoneRef = useRef('');
    const openingBalanceRef = useRef('');

    const date = new Date();
    const today = format(date, "yyyy-MM-dd");
    // State to store the modified array
    const [dataArray, setDataArray] = useState(products);

    // Function to calculate the default total
    const calculateDefaultTotal = (defaultQuantity, purchase) => {
        return defaultQuantity * purchase;
    };

    // Set default values for quantity and total when the component mounts
    useEffect(() => {
        setDataArray(prevDataArray => {
            return prevDataArray.map(item => {
                const defaultQuantity = 1;
                const defaultTotal = calculateDefaultTotal(defaultQuantity, item.purchase);
                return {
                    ...item,
                    quantity: item.quantity || defaultQuantity,
                    total: item.total || defaultTotal,
                };
            });
        });
    }, []);


    if (loading) {
        return <Loader />
    }

    // const handleInputChange = (index, field, value) => {
    //     setDataArray((prevDataArray) => {
    //         const newDataArray = [...prevDataArray];
    //         newDataArray[index] = {
    //             ...newDataArray[index],
    //             [field]: value,
    //         };

    //         const cost = parseFloat(newDataArray[index].purchase) * parseFloat(value);
    //         newDataArray[index].cost = cost;

    //         newDataArray[index].total =
    //             newDataArray[index].quantity * newDataArray[index].purchase;
    //         return newDataArray;
    //     });
    // };

    const handleInputChange = (index, field, value) => {
        const updatedArray = [...dataArray];
        const updatedProduct = { ...updatedArray[index] };

        if (field === 'quantity') {
            updatedProduct[field] = value;

            // Update item.purchase based on the new quantity
            updatedProduct.purchase = purchaseModal.grandTotal / value;

            // Update item.total based on the new quantity and purchase price
            updatedProduct.total = value * updatedProduct.purchase;
        } else {
            // Handle other fields if needed
            updatedProduct[field] = value;
        }

        updatedArray[index] = updatedProduct;
        // Update the state with the modified array
        setDataArray(updatedArray);
    };

    // Function to remove an item from the dataArray
    const handleRemoveItem = index => {
        setDataArray(prevDataArray => {
            const newDataArray = [...prevDataArray];
            newDataArray.splice(index, 1);
            return newDataArray;
        });
    };
    const totalValue = dataArray.reduce((total, item) => total + (item.total || 0), 0);
    // Send the modified data to the server (e.g., on form submission)


    // New Product Functions

    const handleSearchResult = (event) => {
        const searchText = event.target.value.toLowerCase(); // Convert search text to lowercase
        const match = productList.filter(product =>
            product.name.toLowerCase().includes(searchText) ||
            product.code.includes(searchText)
        );
        setSearchText(searchText); // Update search text state
        setSearchResult(match);
        setIsSearching(true);
        const exactMatch = match.find(product => product.code === searchText);

        if (exactMatch) {
            // If an exact match is found, trigger the addProductToInvoice function
            handleAddProductToInvoice(exactMatch);
        }
    }

    const handleAddProductToInvoice = (product) => {
        console.log(product);
        // Check if the product already exists in the dataArray
        const existingProductIndex = dataArray.findIndex((item) => item._id === product._id);

        if (existingProductIndex !== -1) {
            // Product already exists, update the quantity and total
            const updatedDataArray = [...dataArray];
            const defaultQuantity = 1;
            updatedDataArray[existingProductIndex].quantity += 1;
            updatedDataArray[existingProductIndex].total = updatedDataArray[existingProductIndex].quantity * updatedDataArray[existingProductIndex].purchase;
            const rate = updatedDataArray[existingProductIndex].purchase;
            updatedDataArray[existingProductIndex].rate = rate;
            setDataArray(updatedDataArray);
        } else {
            // Product doesn't exist, add it to the dataArray
            setDataArray((prevDataArray) => {
                const defaultQuantity = 1; // Set the default quantity to 1
                const defaultTotal = calculateDefaultTotal(defaultQuantity, product.purchase);
                const rate = product.purchase;
                return [...prevDataArray, { ...product, quantity: defaultQuantity, total: defaultTotal, rate: rate }];
            });
        }

        setIsSearching(false);
        setSearchText('');
        if (beepAudioRef.current) {
            beepAudioRef.current.play();
        }
    }

    // New

    const handleSubmitSales = () => {
        // const purchaseInvoice = invoiceRef.current.value;
        // const purchaseDate = dateRef.current.value;
        // const supplierCode = supplierCodeRef.current.innerText;
        // const purchaseSupplier = supplierRef.current.innerText;
        // const purchaseAddress = addressRef.current.innerText;
        // const purchasePhone = phoneRef.current.innerText;
        // const purchaseOpening = openingBalanceRef.current.innerText;
        // const totalAmount = parseInt(totalRef.current.innerText);
        // const discount = discountAmount + roundingAmount;
        // const grandTotal = parseInt(grandTotalRef.current.value);
        // const paymentdata = parseInt(paymentRef.current.value);
        // const payment = paymentdata ? parseInt(paymentRef.current.value) : 0;
        // const dueAmount = parseInt(dueAmountRef.current.value);
        // const user = userAc?.email.split('@')[0];
        // const month = monthName + " " + yearName;
        // const type = "Pos"

        const data = {
            products: dataArray, ...purchaseModal
        };


        fetch('https://servers.thakurgaonexpress.com/bondhuboxhouse/productions', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(data),
        })
            .then(res => res.json())
            .then(data => {
                toast.success('Production Added Successfull')
                navigate("/manageproductions")
                setTimeout(() => { window.location.reload(); }, 2000);
            })

    }

    let currentDate = new Date();

    // Get the current month and year as strings
    let monthName = currentDate.toLocaleString('default', { month: 'long' });
    let yearName = currentDate.toLocaleString('default', { year: 'numeric' });

    const total = dataArray.reduce((total, currentValue) => total + parseFloat(currentValue?.total), 0);


    const handleUpdateProduct = (product, index) => {
        setUpdatingProduct(product);
        handleRemoveItem(index)
        setIsUpdatingProduct(true);
    }
    const handleCancelUpdate = () => {
        setDataArray(prevDataArray => [...prevDataArray, { ...updatingProduct, _id: updatingProduct._id, total: 1 * updatingProduct.purchase }]);
        setIsUpdatingProduct(false);
    }

    const handleUpdateOldProduct = () => {
        const name = nameRef.current.value;
        const code = codeRef.current.value;
        const category = categoryRef.current.value;
        // const brand=brandRef.current.value
        // const origin=originRef.current.value;
        const unit = unitRef.current.value;
        const purchase = parseFloat(purchaseRef.current.value);
        const sell = parseFloat(sellRef.current.value);
        const minimum = parseFloat(minimumRef.current.value);
        const opening = parseFloat(openingRef.current.value);
        const remarks = remarksRef.current.value;
        const user = userAc?.email.split('@')[0]
        const status = 'Active';
        const approvedBy = false;
        const newData = { name, code, category, unit, purchase, sell, minimum, opening, remarks, user, status, approvedBy }

        fetch(`https://servers.thakurgaonexpress.com/bondhuboxhouse/getproducts/${updatingProduct._id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(newData)
        })
            .then(res => res.json())
            .then(data => {
                toast('Product Upadated Successful');
                setDataArray(prevDataArray => [...prevDataArray, {
                    ...newData, _id: updatingProduct._id,
                    total: 1 * newData.purchase, rate: newData.purchase, quantity: 1
                }]);
                setIsUpdatingProduct(false);
            })
    };

    const handleProductCode = (event) => {
        const code = event.target.value;
        setPdCode(code)
    }

    const closeModal = () => {
        setPurchaseModal(null);
    }


    return (
        <>
            <div className="z-50">
                <input type="checkbox" id="view-modal" class="modal-toggle" />
                <div class="modal mt-12">
                    <div class="modal-box max-w-none w-full lg:w-10/12 lg:ml-64 relative">
                        <label for="view-modal" onClick={closeModal} class="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                        <div className="mx-2 py-4">
                            <h1 className="text-lg font-bold mb-4">Add A Output Product</h1>
                            <div className='flex gap-2'>
                                <div className='flex justify-center lg:justify-start items-center my-4'>
                                    <div class="form-control w-60 mx-auto lg:mx-px">
                                        <input onChange={handleSearchResult} value={searchText} type="text" placeholder="Scan Code/Search Product" class="input text-xs input-bordered border-dark-purple w-full max-w-xs" />
                                    </div>
                                </div>

                                <div className="flex justify-center lg:justify-start items-center my-4">
                                    <button
                                        className="btn btn-sm text-white"
                                        onClick={() => setIsAddingProduct(!isAddingProduct)}
                                    >
                                        {isAddingProduct ? 'Cancel' : 'Add Product'}
                                    </button>
                                </div>
                            </div>


                            <hr />
                            <div style={isSearching ? {} : { visibility: 'hidden' }} className={`grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 bg-gray-200 p-2 ${isSearching ? 'h-60' : "h-8"} w-80 overflow-auto`}>
                                {
                                    isSearching ? searchResult.map(product =>
                                        <label for="update-modal" onClick={() => handleAddProductToInvoice(product)} style={{ width: '300px', height: '50px' }} className={`bg-black bg-opacity-40 text-xs p-3 text-white te  shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer`}>{product?.name}</label>) :
                                        productList.map(product =>
                                            <label for="update-modal" onClick={() => handleAddProductToInvoice(product)} style={{ width: '300px', height: '50px' }} className={`bg-black bg-opacity-40 text-xs p-3 text-white  te  shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer`}>{product?.name}</label>)
                                }
                            </div>


                            <div className='overflow-auto lg:col-span-12 text-xs z-30 mb-4'>
                                <table class="table w-1/2 mx-auto text-xs">
                                    {isAddingProduct && <thead className='text-center'>
                                        <tr>
                                            <th className='bg-red-600 text-white normal-case'>Product Code</th>
                                            <th className='bg-red-600 text-white normal-case w-20'>Product Name</th>
                                            <th className='bg-red-600 text-white normal-case'>Category</th>
                                            <th className='bg-red-600 text-white normal-case'>Unit</th>
                                            <th className='bg-red-600 text-white normal-case'>Purchase</th>
                                            <th className='bg-red-600 text-white normal-case'>Sale</th>
                                            <th className='bg-red-600 text-white normal-case'>Minimum</th>
                                            <th className='bg-red-600 text-white normal-case'>Opening</th>
                                            <th className='bg-red-600 text-white normal-case'>Remarks</th>
                                        </tr>
                                    </thead>}
                                    <tbody className='text-center'>
                                        {isAddingProduct && (
                                            <tr className='text-xs'>
                                                <td>
                                                    <div>
                                                        <input
                                                            type="text"
                                                            placeholder="Product Code"
                                                            defaultValue={getbarcode}
                                                            ref={productCodeRef}
                                                            onChange={handleProductCode}
                                                            className="p-2 border rounded-lg w-24"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <input
                                                            type="text"
                                                            placeholder="Product Name"
                                                            value={newProduct.name}
                                                            onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
                                                            className="p-2 border rounded-lg"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <select value={newProduct.category} onChange={(e) => setNewProduct({ ...newProduct, category: e.target.value })} class="p-2 border rounded-lg" required>
                                                            <option value='' disabled selected>Category</option>
                                                            {
                                                                categories.map(category => <option key={category._id} value={category.name}>{category.name}</option>)
                                                            }

                                                        </select>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <select defaultValue={newProduct.unit} onChange={(e) => setNewProduct({ ...newProduct, unit: e.target.value })} class="p-2 border rounded-lg" required>
                                                            <option value='' disabled selected>Select Unit</option>
                                                            {
                                                                units.map(unit => <option key={unit._id} value={unit.type} >{unit.type}</option>)
                                                            }

                                                        </select>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <input
                                                            type="number"
                                                            placeholder="Purchase Price"
                                                            value={newProduct.purchase}
                                                            onChange={(e) => setNewProduct({ ...newProduct, purchase: parseFloat(e.target.value) })}
                                                            className="p-2 border rounded-lg w-24"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <input
                                                            type="number"
                                                            placeholder="Sales Price"
                                                            value={newProduct.sell}
                                                            onChange={(e) => setNewProduct({ ...newProduct, sell: parseFloat(e.target.value) })}
                                                            className="p-2 border rounded-lg w-24"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <input
                                                            type="number"
                                                            placeholder="Minimum Stock"
                                                            value={newProduct.minimum}
                                                            onChange={(e) => setNewProduct({ ...newProduct, minimum: e.target.value })}
                                                            className="p-2 border rounded-lg w-16"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <input
                                                            type="number"
                                                            placeholder="Opening Stock"
                                                            value={newProduct.opening}
                                                            onChange={(e) => setNewProduct({ ...newProduct, opening: e.target.value })}
                                                            className="p-2 border rounded-lg w-16"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <input
                                                            type="text"
                                                            placeholder="Remarks"
                                                            value={newProduct.remarks}
                                                            onChange={(e) => setNewProduct({ ...newProduct, remarks: e.target.value })}
                                                            className="p-2 border rounded-lg w-28"
                                                        />
                                                    </div>
                                                </td>
                                            </tr>

                                        )}
                                        {
                                            duplicate && (<tr>
                                                <td className='text-red-600 font-bold text-xs'>Duplicate</td>
                                            </tr>)
                                        }

                                        {isAddingProduct && (<tr>


                                            <td>
                                                <div className="col-span-2 text-center">
                                                    <button
                                                        disabled={duplicate}
                                                        className="btn btn-xs btn-primary"
                                                        onClick={handleAddProduct}
                                                    >
                                                        Save Product
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>)

                                        }

                                    </tbody>
                                </table>
                            </div>
                            <div className='overflow-auto lg:col-span-12 text-xs z-30 mb-4'>
                                <table class="table w-1/2 mx-auto text-xs">
                                    {isUpdatingProduct && <thead className='text-center'>
                                        <tr>
                                            <th className='bg-red-600 text-white normal-case'>Product Code</th>
                                            <th className='bg-red-600 text-white normal-case w-20'>Product Name</th>
                                            <th className='bg-red-600 text-white normal-case'>Category</th>
                                            <th className='bg-red-600 text-white normal-case'>Unit</th>
                                            <th className='bg-red-600 text-white normal-case'>Purchase</th>
                                            <th className='bg-red-600 text-white normal-case'>Sale</th>
                                            <th className='bg-red-600 text-white normal-case'>Minimum</th>
                                            <th className='bg-red-600 text-white normal-case'>Opening</th>
                                            <th className='bg-red-600 text-white normal-case'>Remarks</th>
                                        </tr>
                                    </thead>}
                                    <tbody className='text-center'>
                                        {isUpdatingProduct && (
                                            <tr className='text-xs'>
                                                <td>
                                                    <div>
                                                        <input
                                                            type="text"
                                                            placeholder="Product Code"
                                                            disabled
                                                            value={updatingProduct?.code}
                                                            ref={codeRef}
                                                            className="p-2 border rounded-lg w-24"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <input
                                                            type="text"
                                                            placeholder="Product Name"
                                                            defaultValue={updatingProduct?.name}
                                                            ref={nameRef}
                                                            className="p-2 border rounded-lg"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <select defaultValue={updatingProduct?.category} ref={categoryRef} class="p-2 border rounded-lg" required>
                                                            <option value='' disabled selected>Category</option>
                                                            {
                                                                categories.map(category => <option key={category._id} value={category.name}>{category.name}</option>)
                                                            }

                                                        </select>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <select value={updatingProduct?.unit} ref={unitRef} class="p-2 border rounded-lg" required>
                                                            <option value='' disabled selected>Select Unit</option>
                                                            {
                                                                units.map(unit => <option key={unit._id} value={unit.type} >{unit.type}</option>)
                                                            }

                                                        </select>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <input
                                                            type="number"
                                                            placeholder="Purchase Price"
                                                            defaultValue={updatingProduct?.purchase}
                                                            ref={purchaseRef}
                                                            className="p-2 border rounded-lg w-24"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <input
                                                            type="number"
                                                            placeholder="Sales Price"
                                                            defaultValue={updatingProduct?.sell}
                                                            ref={sellRef}
                                                            className="p-2 border rounded-lg w-24"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <input
                                                            type="number"
                                                            placeholder="Minimum Stock"
                                                            defaultValue={updatingProduct?.minimum}
                                                            ref={minimumRef}
                                                            className="p-2 border rounded-lg w-16"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <input
                                                            type="number"
                                                            placeholder="Opening Stock"
                                                            disabled
                                                            value={updatingProduct?.opening}
                                                            ref={openingRef}
                                                            className="p-2 border rounded-lg w-16"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <input
                                                            type="text"
                                                            placeholder="Remarks"
                                                            defaultValue={updatingProduct?.remarks}
                                                            ref={remarksRef}
                                                            className="p-2 border rounded-lg w-28"
                                                        />
                                                    </div>
                                                </td>
                                            </tr>

                                        )}
                                        {isUpdatingProduct && (<tr>


                                            <td>
                                                <div className="col-span-2 text-center">
                                                    <button
                                                        className="btn btn-xs btn-primary"
                                                        onClick={handleUpdateOldProduct}
                                                    >
                                                        Update Product
                                                    </button>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-span-2 text-center">
                                                    <button
                                                        className="btn btn-xs btn-primary"
                                                        onClick={handleCancelUpdate}
                                                    >
                                                        Cancel Update
                                                    </button>
                                                </div>
                                            </td>

                                        </tr>)

                                        }

                                    </tbody>
                                </table>
                            </div>

                            <div className='lg:grid lg:grid-cols-12 gap-2 h-screen'>
                                <div className='overflow-auto lg:col-span-10 text-xs z-30 mb-4'>
                                    <table class="table w-1/2 mx-auto text-xs">
                                        <thead className='text-center'>
                                            <tr>
                                                <th className='bg-red-600 text-white normal-case'>SL#</th>
                                                <th className='bg-red-600 text-white normal-case'>Product Code</th>
                                                <th className='bg-red-600 text-white normal-case w-20'>Product Name</th>
                                                <th className='bg-red-600 text-white normal-case'>Production Qyt</th>
                                                <th className='bg-red-600 text-white normal-case'>Unit</th>
                                                <th className='bg-red-600 text-white normal-case'>Cost Rate</th>
                                                <th className='bg-red-600 text-white normal-case'>Total</th>
                                                <th className='bg-red-600 text-white normal-case'>Action</th>
                                                <th className='bg-red-600 text-white normal-case'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className='text-center'>
                                            {
                                                dataArray?.map((product, index) => <tr key={product._id} className='hover'>
                                                    <td className='h-8'>{index + 1}</td>
                                                    <td className='h-8'>{product?.code}</td>
                                                    <td className='text-xs w-20'>{product?.name}</td>
                                                    <td><input
                                                        type="number"
                                                        className="border border-gray-300 px-2 py-1 rounded-md w-16 text-center"
                                                        defaultValue={product.quantity || 1}
                                                        onChange={e => handleInputChange(index, 'quantity', parseInt(e.target.value))}
                                                    /></td>
                                                    <td className='h-8 w-8'>{product?.unit}</td>
                                                    <td> <input
                                                        type="number"
                                                        className="border border-gray-300 px-2 py-1 rounded-md w-20 text-center"
                                                        value={product.purchase || 0}
                                                        onChange={e => handleInputChange(index, 'purchase', parseFloat(e.target.value))}
                                                    /></td>
                                                    <td><input type="number" value={(product.total)?.toFixed(2) || 0} placeholder="Type here" className="input text-xs input-bordered text-center h-8 w-28 max-w-lg" readOnly /></td>
                                                    <td> <button
                                                        className="bg-red-500 hover:bg-red-600 text-white font-semibold px-4 py-2 rounded-md"
                                                        onClick={() => handleRemoveItem(index)}
                                                    >
                                                        Remove
                                                    </button> </td>
                                                    {product?._id ? <td> <button
                                                        className="bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded-md"
                                                        onClick={() => handleUpdateProduct(product, index)}
                                                    >
                                                        Update
                                                    </button> </td> : <td> <button disabled className='btn btn-warning btn-xs'>New</button></td>}
                                                </tr>)
                                            }
                                        </tbody>
                                        <tfoot className='text-center h-2'>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td className='text-sm font-bold'>Total</td>
                                                <td ref={totalRef} className='text-sm font-bold'>{totalValue.toFixed(2)}</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                <div className='overflow-auto lg:col-span-2 ml-20 lg:ml-0'>
                                    <div style={{ height: '100px' }} className='bg-red-600 w-48  text-xs text-center'>
                                        <button onClick={handleSubmitSales} className='btn btn-sm mt-4 text-white'>Submit Purchase</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div >
        </>
    );
};

export default ViewInvoice;