import { render } from '@testing-library/react';
import React from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { clearEdit } from '../../../../features/InfoSlice/infoSlice';
import { addToProductList } from '../../../../features/PurchaseSlice/PurchaseSlice';


const UpdateModal = ({ updateProduct, setUpdateProduct }) => {
    const products = useSelector((state) => state.purchaseInvoice.products);
    const [total, setTotal] = useState(false);
    const [changingRate, setChangingRate] = useState(false);
    const [quantity, setQuantity] = useState();
    const [defaultQuantity, setDefaultQuantity] = useState();
    const [purchaseRate, setPurchaseRate] = useState();
    const [addError, setAddEroor] = useState();
    const [currentStock, setCurrentStock] = useState();
    const [qytError, setQytError] = useState(false);
    const dispatch = useDispatch();


    const codeRef = useRef('');
    const nameRef = useRef('');
    const quantityRef = useRef();
    const rateRef = useRef();
    const totalRef = useRef();

    const addProduct = () => {
        const code = codeRef.current.innerText;
        const name = nameRef.current.innerText;
        const quantity = quantityRef.current.value;
        const rate = rateRef.current.value;
        const total = totalRef.current.value;
        const opening = products[0]?.opening;
        const openingRate = products[0]?.purchase

        dispatch(addToProductList({ code, name, quantity, rate, total,opening,openingRate }))
        setUpdateProduct(null);
    }

    const handleTotal = (event,productStock) => {
        const quantity = parseFloat(event?.target?.value);
        const newStock= productStock+quantity;
        setCurrentStock(newStock);
        setQuantity(quantity);
        setTotal(true);

        if ((event?.target?.value).slice(0, 1) === '.') {
            setQytError(true)
        } else {
            setQytError(false)
        }
    }
    const handleRate = (event) => {
        const rate = parseFloat(event?.target?.value);
        const quantity = parseFloat(quantityRef.current.value);
        setDefaultQuantity(quantity)
        setPurchaseRate(rate);
        setChangingRate(true);
    }

    const closeModal = () => {
        setUpdateProduct(null);
    }

    return (
        <div>
            <input type="checkbox" id="update-modal" class="modal-toggle" />
            <div class="modal">
                <div class="modal-box max-w-none w-full lg:w-11/12 lg:ml-60 relative">
                    <label for="update-modal" onClick={closeModal} class="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <h3 class="text-lg text-center font-bold">Add Product <span className='text-error'>{addError}</span></h3>
                    <table class="table w-1/2 mx-auto text-xs">
                        <thead className='text-center'>
                            <tr>
                                <th className='bg-red-600 text-white normal-case'>Product Code</th>
                                <th className='bg-red-600 text-white normal-case'>Product Name</th>
                                <th className='bg-red-600 text-white normal-case'>Quantity</th>
                                <th className='bg-red-600 text-white normal-case'>Rate</th>
                                <th className='bg-red-600 text-white normal-case'>Total</th>
                                <th className='bg-red-600 text-white normal-case'>Stock</th>
                                <th className='bg-red-600 text-white normal-case'>Action</th>
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            {
                                products.map(product => <tr className='hover'>
                                    <td className='text-xs' ref={codeRef}>{product.code}</td>
                                    <td className='text-xs' ref={nameRef}>{(product.name).replace(/\([^\)]+\)\s*$/, '')}</td>
                                    <td><input ref={quantityRef} onChange={(event)=>handleTotal(event,product.stock)} type="number" defaultValue={product.quantity} placeholder="Type here" className="input text-center text-xs input-bordered w-28 max-w-lg" /></td>
                                    <td><input ref={rateRef} onChange={handleRate} type="number" defaultValue={product.purchase} placeholder="Type here" className="input text-xs text-center input-bordered w-28 max-w-lg" /></td>
                                    <td><input ref={totalRef} type="number" value={total === false ? product.purchase : changingRate === true ? quantity * purchaseRate  : quantity * parseFloat(product?.purchase).toFixed(2)} placeholder="Type here" className="input text-center text-xs input-bordered w-40 max-w-lg" /></td>
                                    <td><input type="number" value={currentStock?currentStock: product?.stock+1} placeholder="Type here" className="input text-center text-xs input-bordered w-28 max-w-lg" /></td>
                                    <td> <button disabled={qytError} onClick={addProduct} className={`btn btn-sm`}>Add Product</button> </td>
                                </tr>)
                            }

                        </tbody>
                    </table>

                </div>
            </div >
        </div >
    );
};

export default UpdateModal;