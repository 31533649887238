import React from 'react';
import auth from '../../Firebase/Firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Link, useLocation } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { addBookingCustomer } from '../../features/SalesSlice/SalesSlice';
import BusinessInfo from './BusinessInfo';

const Navbar = () => {
  // onClick={() => handleAddBookingCustomer(booking)}

  const [user, loading, error] = useAuthState(auth);
  const location = useLocation();
  const dispatch = useDispatch();

  // console.log(location.pathname);
  //onClick={()=>dispatch(addBookingCustomer("Walking Customer"))}
  // <h1 className='bg-red-600 text-white p-4 font-bold uppercase text-xl'>NBC Service Booking <span> <p className='mr-2'>{user?.email.slice(0,5)}</p> </span> <span><p className=''>{user?<Link onClick={()=>signOut(auth)} to='/'>Log Out</Link>:''}</p></span></h1>
  return (
    <div>
      {
        location.pathname !== "/login" && <div className="navbar bg-red-600 text-white fixed top-0 z-50">
          <div className="flex-1">
            <a className="btn btn-ghost normal-case text-xl"><Link to='/'>{BusinessInfo.name}</Link></a>
          </div>
          <div className="flex-none text-xs">
            <ul className="menu menu-horizontal px-1">
              {/* <li className='invisible lg:visible'><a>{user && 'You Are ' + user.email.slice(0, 5)}</a></li>
              <li className='invisible lg:visible'><a>{user && 'You Are ' + user.email.slice(0, 5)}</a></li> */}
              {/* <li className='invisible lg:visible'></li>
              <li className='invisible lg:visible'></li> */}
              <li className=''>{user ? <Link className='visible lg:invisible ml-20' onClick={() => signOut(auth)} to='/'>Log Out</Link> : ""}</li>
              <li className='ml-12 md:ml-px'><label for="my-drawer-2" tabindex="0" class="btn btn-ghost lg:hidden">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
              </label></li>
              <li> {user && <Link to={`/adddefaultsales`} className="text-white mx-auto invisible lg:visible">Add Sale</Link>}</li>
              {/* <li> {user && <Link to={`/addpossales`} className="text-white mx-auto invisible lg:visible">Pos Sale</Link>}</li> */}
              {/* <li> {user && <Link to={`/addpospurchase`} className="text-white mx-auto invisible lg:visible">Pos Purchase</Link>}</li> */}
              <li> {user && <Link to={`/reports/currentstock`} className="text-white mx-auto invisible lg:visible">Stock List</Link>}</li>
              <li><a className=''>{user ? location.pathname === '/dashboard' ? <Link className='invisible lg:visible' to='/'>Go To Home</Link> : <Link className='invisible lg:visible' to='/'>Go To Dashboard</Link> : ''}</a></li>
              <li className=''>{user ? <Link className='invisible lg:visible' onClick={() => signOut(auth)} to='/'>Log Out</Link> : <Link className='invisible lg:visible' to='/login'></Link>}</li>
            </ul>
          </div>
        </div>
      }
    </div>
  );
};

export default Navbar;