import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    products: [],
    rawMaterial:[],
}

const productionSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        getProducts: (state, action) => {
            state.products = action.payload;
        },
        getRawMaterial: (state, action) => {
            state.rawMaterial = action.payload;
        },
    }
});

export const { getProducts, updatePurchaseOrder,getRawMaterial } = productionSlice.actions;
export default productionSlice.reducer;