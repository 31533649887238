import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';

const useCurrentStockForSales = () => {
    const [sales, setSales] = useState([]);
    const [purchases, setPurchases] = useState([]);

    useEffect(() => {
        const url = `https://servers.thakurgaonexpress.com/bondhuboxhouse/getsalesproducts`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                setSales(data);
            })
    }, []);

    useEffect(() => {
        const url = `https://servers.thakurgaonexpress.com/bondhuboxhouse/getpurchaseproducts`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                setPurchases(data);
            })
    }, []);

    const { data: products, isLoading } = useQuery(['products'], () => fetch('https://servers.thakurgaonexpress.com/bondhuboxhouse/getproducts').then(res => res.json()));



    // Stock Report
    // Sales Quantity

    let array = [];

    for (let i = 0; i < products?.length; i++) {
        const filtered = sales.filter(sale => sale?.code === products[i]?.code);
        array.push(filtered);
    }

    let array2 = [];
    for (let i = 0; i < array?.length; i++) {
        const salesQuantity = array[i]?.map(a => parseFloat(a.quantity));
        const totalSales = salesQuantity?.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
        array2.push(totalSales);
    }

    // Purchase Quantity

    let array3 = [];
    for (let i = 0; i < products?.length; i++) {
        const filtered = purchases.filter(purchase => purchase.code === products[i].code);
        array3.push(filtered);
    }

    let array4 = [];

    for (let i = 0; i < array?.length; i++) {
        let loanReturnAccounts = array3[i]?.map(a => parseFloat(a.quantity));
        const totalLoanReturn = loanReturnAccounts?.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        array4.push(totalLoanReturn)
    }


    // Joining Balance To data

    let stockData = [];
    for (let i = 0; i < products?.length; i++) {
        const product = ({
            _id: products[i]._id, name: products[i]?.name,
            code: products[i].code, category: products[i].category,
            brand: products[i].brand, minimum: products[i].minimum,
            origin: products[i].origin, unit: products[i].unit, status: products[i].status,
            purchase: products[i].purchase, sell: products[i].sell, totalSale: array2[i],
            totalPurchase: array4[i], opening: products[i].opening,
            stock: (products[i]?.opening) + array4[i] - array2[i], stockCost: (products[i].opening
                + array4[i] - array2[i]) * products[i].purchase,
        });

        stockData.push(product);
    }

    const filtered = stockData?.filter((product) => product.stock > 0);


    // if (isLoading) {
    //     return <Loader />
    // }

    return { filtered, stockData, isLoading };
};

export default useCurrentStockForSales;




// import { useState, useEffect } from 'react';
// import { useQuery } from 'react-query';

// const useCurrentStockForSales = () => {
//     const [sales, setSales] = useState([]);
//     const [purchases, setPurchases] = useState([]);

//     useEffect(() => {
//         const urlSales = `https://servers.thakurgaonexpress.com/bondhuboxhouse/getsalesproducts`;
//         fetch(urlSales)
//             .then(res => res.json())
//             .then(data => {
//                 setSales(data);
//             });
//     }, []);

//     useEffect(() => {
//         const urlPurchases = `https://servers.thakurgaonexpress.com/bondhuboxhouse/getpurchaseproducts`;
//         fetch(urlPurchases)
//             .then(res => res.json())
//             .then(data => {
//                 setPurchases(data);
//             });
//     }, []);

//     const { data: products, isLoading } = useQuery(['products'], () =>
//         fetch('https://servers.thakurgaonexpress.com/bondhuboxhouse/getproducts').then(res => res.json())
//     );

//     const getPurchaseForSale = (productCode, quantity) => {
//         let remainingQuantity = quantity;
//         let selectedPurchases = [];

//         for (const purchase of purchases) {
//             if (purchase.code === productCode) {
//                 const availableQuantity = Math.min(purchase.quantity, remainingQuantity);

//                 if (availableQuantity > 0) {
//                     selectedPurchases.push({
//                         ...purchase,
//                         quantity: availableQuantity,
//                     });

//                     remainingQuantity -= availableQuantity;
//                 }

//                 if (remainingQuantity <= 0) {
//                     break;
//                 }
//             }
//         }

//         return selectedPurchases;
//     };

//     // Stock Report - Sales Quantity
//     let array = [];
//     for (let i = 0; i < products?.length; i++) {
//         const filtered = sales.filter(sale => sale?.code === products[i]?.code);
//         array.push(filtered);
//     }

//     let array2 = [];
//     for (let i = 0; i < array?.length; i++) {
//         const salesQuantity = array[i]?.map(a => parseFloat(a.quantity));
//         const totalSales = salesQuantity?.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
//         array2.push(totalSales);
//     }

//     // Purchase Quantity
//     let array3 = [];
//     for (let i = 0; i < products?.length; i++) {
//         const productCode = products[i]?.code;
//         const salesQuantity = array2[i] || 0; // Default to 0 if salesQuantity is undefined
//         const selectedPurchases = getPurchaseForSale(productCode, salesQuantity);

//         // Use selectedPurchases for further calculations
//         array3.push(selectedPurchases);
//     }

//     let array4 = [];

//     for (let i = 0; i < array?.length; i++) {
//         let loanReturnAccounts = array3[i]?.map(a => parseFloat(a.quantity));
//         const totalLoanReturn = loanReturnAccounts?.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
//         array4.push(totalLoanReturn)
//     }


//     // Joining Balance To data

//     let stockData = [];
//     for (let i = 0; i < products?.length; i++) {
//         const product = ({
//             _id: products[i]._id, name: products[i]?.name,
//             code: products[i].code, category: products[i].category,
//             brand: products[i].brand, minimum: products[i].minimum,
//             origin: products[i].origin, unit: products[i].unit, status: products[i].status,
//             purchase: products[i].purchase, sell: products[i].sell, totalSale: array2[i],
//             totalPurchase: array4[i], opening: products[i].opening,
//             stock: (products[i]?.opening) + array4[i] - array2[i], stockCost: (products[i].opening
//                 + array4[i] - array2[i]) * products[i].purchase,
//         });

//         stockData.push(product);
//     }

//     const filtered = stockData?.filter((product) => product.stock > 0);


//     // if (isLoading) {
//     //     return <Loader />
//     // }

//     return { filtered, stockData, isLoading };
// };

// export default useCurrentStockForSales;

