import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    products: [],
    productList: [],
    quantity: [],
    duplicateError: false,
    customer: [],
    updateSales: [],
    details: {},
    bookingCustomer: [],
    posCustomer: [],
    isPos: false,
    purchaseProducts: [],
    updatePurchaseProducts: [],


}

const SalesSlice = createSlice({
    name: 'salesInvoice',
    initialState,
    reducers: {
        addToSalesInvoice: (state, action) => {
            const newProduct = { ...action.payload, quantity: 1 };
            if (state.products.length) {
                state.products[0] = newProduct;
            } else {
                state.products.unshift(newProduct);
            }
        },
        addToProductList: (state, action) => {
            const selectedProduct = state.productList.find(product => product.code === action.payload.code);
            if (!selectedProduct) {
                const newOne = action.payload;
                state.productList.push(newOne);
            }
            // else{
            //     state.duplicateError=true;
            // }
        },
        addQuantiy: (state, action) => {
            const selectedProduct = state.products.find(product => product._id === action.payload.id);
            if (selectedProduct) {
                state.products.quantity = action.payload.quantity;
            } else {
                state.products.quantity = 1;
            }
        },
        removeProductFromList: (state, action) => {
            const selectedProduct = state.productList.filter(product => product.code !== action.payload.code);
            state.productList = selectedProduct;
        },
        selectCustomer: (state, action) => {
            state.customer = action.payload;
        },
        updateSales: (state, action) => {
            state.updateSales = action.payload;
        },
        addToUpdateSales: (state, action) => {
            const selectedProduct = state.updateSales.products.find(product => product.code === action.payload.code);
            if (!selectedProduct) {
                const newProducts = action.payload;
                state.updateSales.products.push(newProducts);
            }
        },
        removeFromUpdateList: (state, action) => {
            const selectedProduct = state.updateSales.products.filter(product => product.code !== action.payload.code);
            state.updateSales.products = selectedProduct;
        },
        addUpdateDetails: (state, action) => {
            state.details = action.payload;
        },
        addBookingCustomer: (state, action) => {
            state.bookingCustomer = action.payload;
        },
        selectPosCustomer: (state, action) => {
            state.posCustomer = action.payload;
            state.isPos = true;
        },
        addPurchaseProducts: (state, action) => {
            state.purchaseProducts = action.payload;
        },
        updatePurchaseProducts: (state, action) => {
            state.updatePurchaseProducts = action.payload;
        },

    }
});

export const { addToSalesInvoice, addToProductList, addQuantiy, removeProductFromList,
    selectCustomer, updateSales, addToUpdateSales, removeFromUpdateList, addUpdateDetails,
    addBookingCustomer, selectPosCustomer, addPurchaseProducts, updatePurchaseProducts } = SalesSlice.actions;
export default SalesSlice.reducer;