import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Shared/Loader';
import { useRef } from 'react';
import AddSalesProductList from './AddSalesProductList';
import AddSalesInvoice from './AddSalesInvoice';
import { addUpdateDetails,selectCustomer } from '../../../../features/SalesSlice/SalesSlice';


const UpdateBikeSalesInvoice = () => {
    // const { discount, dueAmount, grandTotal, payment, products, purchaseDate, purchaseInvoice, purchaseSupplier, totalAmount } = updateModal;

    const customer = useSelector((state) => state.salesInvoice.customer);
    const [isSearching, setIsSearching] = useState(false);
    const [searchResult, setSearchResult] = useState([]);
    const [searchText, setSearchText] = useState([]);
    const [loading, setLoading] = useState(true);
    const [customers, setCustomers] = useState([]);
    // const [details, setDetails] = useState();
    const [defaultCustomer, setDefaultCustomer] = useState(false);

    const {updateSales } = useSelector((state) => state.salesInvoice);


    const customerCodeRef = useRef('');
    const customerRef = useRef('');
    const dateRef = useRef('');
    const addressRef=useRef('');
    const phoneRef=useRef('');
    const openingRef=useRef('');
    const invoiceRef=useRef('');

    const dispatch = useDispatch();



    useEffect(() => {
        fetch('https://servers.thakurgaonexpress.com/bondhuboxhouse/getmaincustomers')
            .then(res => res.json())
            .then(data => {
                setCustomers(data);
                setLoading(false)
            })
    }, [])


    if (loading) {
        return <Loader />
    }

    const handleSearchResult = (event) => {
        setSearchText(event.target.value)
        const match = customers.filter(customer => customer.name.includes(searchText)
            || customer.name.toLowerCase().includes(searchText) ||
            customer.name.toUpperCase().includes(searchText));
        setSearchResult(match);
        setIsSearching(true);
    }

    const handleSelectCustomer = (customer) => {
        dispatch(selectCustomer(customer))
        setIsSearching(false)
        setSearchText('');
        setDefaultCustomer(true)

        setTimeout(()=>{handleSalesDetails()}, 2000);
        // const purchaseInvoice = invoiceRef.current.value;
        // const purchaseSupplier = supplierRef.current.innerText;
        // const purchaseAddress = addressRef.current.innerText;
        // const purchasePhone = phoneRef.current.innerText;
        // const purchaseOpening = openingRef.current.innerText;
        // const purchaseDate = dateRef.current.value;
        // dispatch(addUpdateDetails({ purchaseInvoice, purchaseSupplier, purchaseAddress, purchasePhone, purchaseOpening, purchaseDate }))

    }


    const handleSalesDetails = (event) => {
        const salesInvoice = invoiceRef.current.value;
        const customerCode = customerCodeRef.current.innerText;
        const salesCustomer = customerRef.current.innerText;
        const salesAddress = addressRef.current.innerText;
        const salesPhone = phoneRef.current.innerText;
        const salesOpening = parseFloat(openingRef.current.innerText);
        const salesDate = dateRef.current.value;
        // setDetails({ purchaseInvoice, purchaseSupplier, purchaseAddress, purchasePhone, purchaseOpening, purchaseDate })
        dispatch(addUpdateDetails({  salesInvoice,customerCode,  salesCustomer,  salesAddress,  salesPhone,  salesOpening,  salesDate }))
    }


    return (
        <div>
            <div className='w-full px-5 pb-5 my-5 text-xs'>
                <h1 className='text-lg text-center bg-red-600 p-3 text-white rounded-xl'>Update Sales</h1>
                <div className='grid grid-cols-1 lg:grid-cols-12 gap-4'>
                    <div class="form-control w-full lg:w-60 max-w-xs mt-5 col-span-2">
                        <div class="form-control lg:w-60 mx-auto">
                            <input onChange={handleSearchResult} value={searchText} type="text" placeholder="Search Supplier" class="rounded-lg bg-gray-300 text-black p-3 text-white text-center focus:border-blue-500 focus:outline-none w-full max-w-xs" />
                        </div>
                        <div style={isSearching ? {} : { visibility: 'hidden' }} className={`grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 bg-gray-200 p-2 ${isSearching ? 'h-16' : "h-8"} w-80 overflow-auto`}>
                            {
                                isSearching ? searchResult.map(customer =>
                                    <label for="update-modal" onClick={() => handleSelectCustomer(customer)} style={{ width: '300px', height: '50px' }} className={`bg-black bg-opacity-40 p-3 text-white text-xs shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer`}>{customer?.name}</label>) :
                                    customers.map(customer =>
                                        <label for="update-modal" onClick={() => handleSelectCustomer(customer)} style={{ width: '300px', height: '50px' }} className={`bg-black bg-opacity-40 p-3 text-white text-xs shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer`}>{customer?.name}</label>)
                            }
                        </div>
                    </div>
                    <div className='lg:col-span-6 mt-4 overflow-auto lg:lg:overflow-visible'>
                        <table class="table lg:w-1/2 lg:ml-32">
                            <thead className='text-center'>
                                <tr>
                                    <th className='bg-red-600 text-white normal-case'>Customer Code</th>
                                    <th className='bg-red-600 text-white normal-case'>Customer Name</th>
                                    <th className='bg-red-600 text-white normal-case'>Customer Address</th>
                                    <th className='bg-red-600 text-white normal-case'>Mobile No</th>
                                    <th className='bg-red-600 text-white normal-case'>Previews Due</th>
                                </tr>
                            </thead>
                            <tbody className='text-center'>
                                <tr className=''>
                                    <td ref={customerCodeRef}>{defaultCustomer === true ? customer?.code : updateSales?.customerCode}</td>
                                    <td onChange={handleSalesDetails} ref={customerRef} className='h-8'>{defaultCustomer === true ? customer?.name : updateSales?.salesCustomer}</td>
                                    <td ref={addressRef}>{defaultCustomer === true ? customer?.address : updateSales?.salesAddress}</td>
                                    <td ref={phoneRef}>{defaultCustomer === true ? customer?.phone : updateSales?.salesPhone}</td>
                                    <td ref={openingRef}>{defaultCustomer === true ? customer?.opening : updateSales?.salesOpening}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <br />
                    <div className='lg:col-span-4 mt-4'>
                        <table class="table w-1/2 mx-auto">
                            <thead className='text-center'>
                                <tr>
                                    <th className='bg-red-600 text-white normal-case'>Select Date</th>
                                    <th className='bg-red-600 text-white normal-case'>Invoice No</th>
                                </tr>
                            </thead>
                            <tbody className='text-center'>
                                <tr className=''>
                                    <td><input onChange={handleSalesDetails} ref={dateRef} type="date" defaultValue={updateSales?.salesDate} className='rounded-lg bg-gray-200 p-3  text-center placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs' /></td>
                                    <td><input onChange={handleSalesDetails} ref={invoiceRef} type="text" defaultValue={updateSales?.salesInvoice} className='rounded-lg bg-gray-200 p-3 w-40 text-center placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs' /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='grid grid-cols-1 relative'>
                    <AddSalesProductList />
                    <AddSalesInvoice />
                </div>
            </div>
        </div>
    );
};

export default UpdateBikeSalesInvoice;